@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  scroll-behavior: smooth;
}
body {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  background: #ffffff;
  scroll-behavior: smooth;
}
input,
select {
  outline: none;
}
.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  gap: 8px;
}
.pagination .page-num {
  padding: 4px 11px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}
.pagination .page-num:hover {
  /* background-color: #f4aa8f; */
  color: #f4aa8f;
  border-radius: 100%;
  border-color: #260c03;
  /* border: #f4aa8f; */
}
.pagination .active {
  /* background-color: #f4aa8f; */
  color: #391508;
  border-radius: 100%;
  border: 1px solid;
  border-color: #f4aa8f;
}

/* @media print {
  .PrintSection {
    padding: 10px !important;
  }
} */
